<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const page = usePage();

const tenant = page.props.currentTenant;

const props = defineProps({
  logoType: { type: String, default: 'logo' },
});

const src = computed(() => {
  if (tenant?.brand_settings && tenant.brand_settings[`${props.logoType}_path`]) {
    return route('dashboard.logo', {
      slug: tenant.slug,
      logoType: props.logoType,
    });
  }

  if (tenant?.brand_settings && tenant.brand_settings.logo_path) {
    return route('dashboard.logo', {
      slug: tenant.slug,
      logoType: 'logo',
    });
  }

  return props.logoType === 'logo' ? '/images/logo.svg' : '/images/logo-light.svg';
});
</script>

<template>
  <img :src="src" alt="Logo" />
</template>
